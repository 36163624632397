import { h } from "vue";
import { useRouter } from "vue-router";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { DataProps } from "@/views/User/ApprovalProcess/ApprovalChain/ProgramChain/type";
function isEmptyNode(chain: DataProps) {
  // const exculdeColumns = ['status', 'chianName', 'buName', 'makeName']
  // return Object.keys(chain).some(key => {
  //   if (!exculdeColumns.includes(key)) {
  //     return chain[key].length === 0
  //   }
  // })
}
const useDynamicColumn = <T>(data: T[], isMake: boolean) => {
  const originColumn: ColumnProps[] = [
    {
      title: "Status",
      dataIndex: "status",
      slots: {
        customRender: "status",
      },
      width: 80,
      fixed: "left",
    },
    {
      title: "Approval Chain",
      dataIndex: "chainName",
      width: 240,
      customRender: ({ text, record }) => {
        const router = useRouter();
        const _text = text.length > 20 ? text.substring(0,20) + '……' : text
        const children = [
          h(
            "span",
            {
              class: "text",
              onClick: () => {
                console.log("record================",record)
                router.push({
                  path: "/approvalChainEdit",
                  query: {
                    bu: record.bu,
                    chainName: record.chainName,
                    businessId: record.businessId,
                    type: isMake ? 'payout' : 'program',
                    make: isMake ? record.make : undefined
                  }
                });
              },
            },
            _text
          ),
        ];
        if (record.isFull !== 1) {
          children.push(
            h("span", {
              class: "font_family icon-old-Warning icon",
            })
          );
        }
        return h("div", { class: "chain-wrapper" }, children);
      },
      fixed: "left",
    },
    {
      title: "BU",
      dataIndex: "bu",
      // slots: { title: "customTitle" },
      width: 100,
      fixed: "left",
    },
  ];
  if (isMake) {
    originColumn.push({
      title: "Make",
      dataIndex: "make",
      width: 100,
      fixed: "left",
    });
  }
  let max = 0;
  data.forEach((item: any) => {
    if (item.level) {
      const lCount = Object.keys(item.level).length;
      if (lCount > max) {
        max = lCount;
      }
    }
    // Object.keys(item).forEach((key) => {
    //   if (item[key] instanceof Array) {
    //     lCount++;
    //   }
    // });
    // if (lCount / 2 > max) {
    //   max = lCount / 2;
    // }
  });
  const width = max > 10 ? '120px' : 'auto'
  for (let i = 0; i < max; i++) {
    originColumn.push({
      title: `L${i + 1}`,
      dataIndex: `L${i + 1}`,
      width,
      customRender: ({ text }) => {
        if (!text) return;
        return h(
          "div",
          {},
          text.map((item: string) => {
            return h("div", {}, item);
          })
        );
      },
    });
  }

  return originColumn;
};
export default useDynamicColumn;
