import {ref, computed, Ref} from "vue";
import {
  getListProgramApproval,
  getApprovalChainDetail,
  getAllRolesByUser,
  saveApprovalRole,
  addApprovalChainNewVersion
} from "@/API/approvalChain";
import useDynamicColumn from "./useDynamicColumn";
import useMock from "@/utils/payment/useMock";
import { DataProps } from "@/views/User/ApprovalProcess/ApprovalChain/ProgramChain/type";
import Data from "@/views/User/ApprovalProcess/ApprovalChain/ProgramChain/mock";
import {Modal} from "ant-design-vue";
interface DataItem {
  key: string;
  approvalLevel: string;
  approvalChainRole: any;
  approvalChainRoleSelectList: any;
  approvalChainUser: any;
  approvalChainUserSelectList: any;
  levelTimes: number;
}
interface RoleItem {
  key: string;
  value: string;
}
const useProgramChian = () => {
  const res = ref();
  const formatList = (list: DataProps[], type: string) => {
    return list.map((item: any) => {
      // Object.keys(item).forEach((key) => {
      //   if (key.indexOf(type) > 0) {
      //     item[key.replace(type, "")] = item[key];
      //   }
      //   if (key.indexOf('User') > 0 || key.indexOf('Role') > 0) {
      //       delete item[key]
      //   }
      // });
      if (item.level) {
        Object.keys(item.level).forEach((i) => {
          item[`L${i}`] = item.level[i].map((level: any) => {
            return level[type];
          });
        });
      }

      return item;
    });
  };
  const ChainUserList = computed(() => {
    const list = res.value;
    if (list) {
      const _list = JSON.parse(JSON.stringify(list));
      return formatList(_list, "userName");
    } else {
      return [];
    }
  });
  const ChainRoleList = computed(() => {
    const list = res.value;
    if (list) {
      const _list = JSON.parse(JSON.stringify(list));
      return formatList(_list, "roleName");
    } else {
      return [];
    }
  });
  const column = ref();
  const bu = ref([])
  const editableFlag = ref<boolean>(false)
  const mode = ref('detail')
  const getChianList = () => {
    getListProgramApproval({
      bus: bu.value
    }).then((data) => {
      res.value = data;
      column.value = useDynamicColumn<DataProps>(res.value, false);
    });
    // useMock<DataProps>(Data).then((data) => {
    //   res.value = data;
    //   column.value = useDynamicColumn<DataProps>(res.value?.data?.data, false);
    // });
  };
  const entityOptions = ref<any>();
  const userOptions = ref<any>();
  const getAllRolesByuserName = (userName: string) => {
    const rolesArray = [] as RoleItem[]
    // getAllRolesByUser(userName).then((res: any) => {
    //   entityOptions.value = res
    //   console.log('entityOptions.value======',entityOptions.value)
    // })
  }
  const dataSource: Ref<DataItem[]> = ref([
    {
      key: '1',
      approvalLevel: 'Level1',
      approvalChainRole: [['eee','BU Central E4'],['ddd','Controlling E4']],
      approvalChainRoleSelectList:[],
      approvalChainUserSelectList: [],
      approvalChainUser: [['userId1','Lifang'],['userId2','lily']],
      levelTimes: 2
    },
    {
      key: '2',
      approvalLevel: 'Level2',
      approvalChainRole: [['eee','BU Central E4'],['ddd','Controlling E4']],
      approvalChainRoleSelectList:[],
      approvalChainUserSelectList: [],
      approvalChainUser: [['userId3','Li,Hao'],['userId3','Li,Hao']],
      levelTimes: 2
    },
  ]);
  const getApprovalChainDetails = (chainId: string) => {
    getApprovalChainDetail(chainId).then((res: any) => {
      console.log('返回审批流详情结果：',res[0])
      dataSource.value = res[1] as DataItem[]
    })
  }
  const saveApprovalChain = (bu: string,chainName: string,effectiveFrom: string,businessId: string) => {
    //const datalist = []
    const saveParams = {
      bu:bu,
      businessId:businessId,
      chainName:chainName,
      effectiveFrom:effectiveFrom,
      dataList: dataSource.value
    }
    console.log('保存审批流传接口的参数=============',saveParams)
    saveApprovalRole(saveParams).then((res: any)=>{
      console.log('保存审批流详情结果：',res)
      if(res.code === '0'){
        editableFlag.value = false
        mode.value = 'detail'
        Modal.info({
          title: 'Tips',
          content: 'Sucessfully saved!'
        })
      }
      else
      {
        Modal.error({
          title: 'Tips',
          content: res.message
        })
      }
    })
  }
  //#region [新增审批流版本]
  const addNewVersion = (newVersionParas: {}) => {
    return addApprovalChainNewVersion(newVersionParas).then((res: any)=>{
      console.log('新增审批流版本返回结果=================',res)
      dataSource.value = res.data as DataItem[]
    })
  }
  //#endregion
  //#end
  return {
    getChianList,
    getApprovalChainDetails,
    getAllRolesByuserName,
    dataSource,
    ChainUserList,
    ChainRoleList,
    column,
    bu,
    entityOptions,
    userOptions,
    saveApprovalChain,
    editableFlag,
    mode,
    addNewVersion
  };
};
export default useProgramChian;
