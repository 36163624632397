
import { computed, defineComponent, reactive, Ref, ref, UnwrapRef } from 'vue';
import { EditOutlined,PlusCircleOutlined,DeleteOutlined } from '@ant-design/icons-vue';
import deepClone from '@/utils/deepClone'
import {useRoute, useRouter} from "vue-router";
import useProgramChian from "@/hooks/approvalChian/useProgramChian";
import * as API from "@/API/approvalChain";

interface DataItem {
  key: string;
  approvalLevel: string;
  approvalChainRole: string[];
  approvalChainUser: string[];
  levelTimes: number;
}

export default defineComponent({
  name:'customApprovalChainTableFirst',
  components: {
    //EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      getAllRolesByuserName,
      addNewVersion,
      dataSource
    } = useProgramChian();
    const columns = [
      {
        dataIndex: 'approvalLevel',
        slots: { title: 'customTitle', customRender: 'approvalLevel' },
      },
      {
        title: 'ApprovalRole',
        dataIndex: 'approvalRole',
        width: '30%',
        slots: { customRender: 'approvalRole' },
      },
      {
        title: 'User',
        dataIndex: 'user',
        width: '30%',
        slots: { customRender: 'user' },
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ];
    /*const dataSource = ref([
      {
        key: '0',
        approvalLevel: 'Level1',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '1',
        approvalLevel: 'Level2',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '2',
        approvalLevel: 'Level3',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '3',
        approvalLevel: 'Level4',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '4',
        approvalLevel: 'Level5',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '5',
        approvalLevel: 'Level6',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '6',
        approvalLevel: 'Level7',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '7',
        approvalLevel: 'Level8',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '8',
        approvalLevel: 'Level9',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '9',
        approvalLevel: 'Level10',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '10',
        approvalLevel: 'Level11',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '11',
        approvalLevel: 'Level12',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '12',
        approvalLevel: 'Level13',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '13',
        approvalLevel: 'Level14',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
      {
        key: '14',
        approvalLevel: 'Level15',
        approvalChainRole: [''],
        approvalChainUser: [''],
        levelTimes: 1
      },
    ]);*/
    if(route.query){
      console.log('进入查询新版本详情接口！')
      console.log('route.query====',route.query)
      const newVersionApprovalParams = {
        approvalBusinessId: route.query.approvalBusinessId as string,
        bu: route.query.bu as string,
        newVersionEffectiveFrom: route.query.newVersionEffectiveFrom as string,
        oldVersionEffectiveFrom: route.query.oldVersionEffectiveFrom as string
      }
      addNewVersion(newVersionApprovalParams)
    }
    const count = computed(() => dataSource.value.length + 1);
    const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
    const mode = ref('detail')
    const levelTimes = ref<number>(1)
    const editableFlag = ref<boolean>(false)
    const entity = undefined
    const doEdit = () => {
      editableFlag.value = true
      mode.value = 'save'
    }
    const save = (key: string) => {
      Object.assign(dataSource.value.filter(item => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
    const deleteModalVisible = ref<boolean>(false)
    const onDelete = (record: any,index: number) => {
      if(record.levelTimes == 1){
        dataSource.value = dataSource.value.filter(item => item.key !== record.key);
        dataSource.value.map((item,index) =>{
          item.key = index.toString()
          item.approvalLevel = 'Level'+`${index+1}`
        })
        console.log('dataSource===',dataSource)
      }
      else{
        record.approvalRole.splice(index,1)
        record.user.splice(index,1)
        record.levelTimes--
      }
      /*dataSource.value = dataSource.value.map((item) => {
        if(item.key == record.key){
          if(item.levelTimes>=2){
            item.levelTimes--
            return item
          }
          else{
            return item
          }
        }
        else {
          return item
        }
      })*/
    };
    const handleAdd = () => {
      /*const newData = {
        key: `${count.value}`,
        approvalLevel: `Level ${count.value}`,
        approvalChainRole: [['','']],
        approvalChainUser: [['','']],
        levelTimes: 1
      };*/
      const newData = {
        key: `${count.value}`,
        approvalLevel: `Level ${count.value}`,
        approvalChainRole: [['','']],
        approvalChainRoleSelectList:[],
        approvalChainUserSelectList: [],
        approvalChainUser: [['','']],
        levelTimes: 1
      };
      dataSource.value.push(newData);
    };
    const handleAddRoleAndUser = (record: any,index: number) => {
      console.log('record====',record)
      record.approvalRole.push(record.approvalRole[index])
      record.user.push(record.user[index])
      record.levelTimes++
    }
    const entityOptions = ref<string[]>(['Controling B4', 'BU Central', 'CFO', 'COO']);
    const tabVisable = ref<boolean>(false)
    const ntimes = ref<number>(0)
    const isQuerySelectRolesFlg = ref<boolean>(false)
    const changeRole = (user: any,index: any,record: any) => {
      // console.log('新的datasource的值为：',dataSource.value)
      // console.log('index and key====',index,record.key)
      const role = entityOptions.value.find((item: any) => item[0] === user) as string
      record.approvalChainRole[index][1] = role[1]
      isQuerySelectRolesFlg.value = false
      API.getAllUsersByRole(user,route.query.bu as string).then((res: any)=>{
        console.log('getAllUsersByRole接口返回结果====',res)
        record.approvalChainUser[index] = res[0]
        console.log('更改后的datasource======',dataSource.value)
      })
    }
    const selectRoles = (value: any,index: any,key: any) => {
      console.log('点击seleceRoles传参：')
      if(route.query.Totype == 'new' && isQuerySelectRolesFlg){
        console.log('是否查询下拉role==========')
        getAllRolesByuserName("")
      }
    }
    return {
      columns,
      onDelete,
      handleAdd,
      handleAddRoleAndUser,
      dataSource,
      editableData,
      editableFlag,
      entity,
      entityOptions,
      levelTimes,
      count,
      doEdit,
      //edit,
      save,
      mode,
      tabVisable,
      ntimes,
      deleteModalVisible,
      //changeApprovalRole,
      isQuerySelectRolesFlg,
      addNewVersion
    };
  },
});
